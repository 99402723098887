.userdashImg img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.dashNav{
    background-color: #f8f9fa;
    border-radius: 15px;
}
.navdash{
    margin-bottom: 1px;
    padding: 12px 15px;
    background-color: #fff;
    font-size: 15px;
    color: #262626;
    transition: all .3s;
}
.navdash:hover{
    background-color: #006B3C;
    color: #fff;
}
.blocimg img{
    aspect-ratio: 7 / 4;
    object-fit: cover;
    border-radius: 15px;
}
.dashoption{
    font-size: 14px;
}
.roundednotofication {
    position: absolute;
    width: 14px;
    display: inline-block;
    height: 14px;
    background-color: #fff;
    border-radius: 50%;
    color: #000;
    font-size: 9px;
    text-align: center;
    line-height: 14px;
    right: -10px;
    top: -5px;
}
.answerList{
    margin-bottom: 2px;
    background-color: #f7f7f7;
    position: relative;
    padding: 12px 120px 12px 40px;
    font-size: 18px;
}
.answercomment{
    position: absolute;
    right: 10px;
    top: 13px;
}
.answerIcon {
    position: absolute;
    left: 12px;
    top: 14px;
}
.correctAnswer{
    background: #dff7df;
}
.wrongAnswer{
    background: #ffeded;
}
.certificate-container {
    padding: 0px;
    width: 1024px;
    margin: auto;
}
.certificate {
    border: 20px solid #006b3c;
    padding: 25px;
    height: 600px;
    position: relative;
}
.ql-editor{
    height: 200px !important;
}


.certificate-header{
    text-align: center;
}
.certificate-header > .logo {
    width: auto;
    height: 43px;
}

.certificate-title {
    text-align: center;    
}

.certificate-body {
    text-align: center;
    margin-top: 30px;
}

.certificate h1 {

    font-weight: 400;
    font-size: 48px;
    color: #0C5280;
}

.student-name {
    font-size: 30px;
    font-weight: 800;
}

.certificate-content {
    margin: 0 auto;
    width: 750px;
}

.about-certificate {
    width: 380px;
    margin: 0 auto;
}

.topic-description {

    text-align: center;
}


